
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from "react-helmet";



const openGraph = () => {
  return (
    <>
      <StaticQuery
        query={graphql`
          query openGrap {
            allWordpressAcfOptions {
                nodes {
                  options {
                    open_graph {
                      brand
                      description
                      image {
                        source_url
                      }
                      title
                    }
                  }
                }
              }
          }
        `}
        render={data => {
          const result = data.allWordpressAcfOptions.nodes[0].options.open_graph;
          return (
          <Helmet>
            <meta property="og:type" content='website' />
            <meta property="og:url" content='https://verinite.com/' />
            <meta property="og:title" content={result.title} />
            <meta property="og:description" content={result.description} />
            <meta property="og:image" content={result.image.source_url} />
            <meta property="og:site_name" content={result.brand} />
            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="twitter:domain" content={result.brand}/>
            <meta property="twitter:url" content="https://verinite.com"/>
            <meta name="twitter:title" content={result.title}/>
            <meta name="twitter:description" content={result.description}/>
            <meta name="twitter:image" content={result.image.source_url}/>

          </Helmet>

          //   <GatsbySeo

          //   openGraph={{
          //     type: 'website',
          //     url: 'https://verinite.com/',
          //     title: result.title,
          //     description: result.description,
          //     images: [
                
          //       { url: result.image.source_url },
              
          //     ],
          //     site_name: result.brand,
          //   }}
          //   twitter={{
          //     handle: '@verinite',
          //     site: '@verinite',
          //     cardType: 'summary_large_image',
          //   }}
          // />
            )
        }}
      />
    </>
  )
}

export default openGraph
